import React from "react";
import { Button, Layout, Col, Row, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";

import LanguageSelector from "../Containers/LanguageSelector";
import Text from "./Text";
import logo from "../logo.png";

const { Header } = Layout;

/*
 * There is some superfluous code in this component, but has been left in for
 * now. This code is to add a navigation menu on the left hand side if there
 * is a navigation menu. Want to keep this header the same as other applications
 * that require a nav menu, would also make it easier to add this in.
 * To add navigation, simply change ui.navMenuEnabled in store/initial.js
 * to true.
 */

export default (props) => {
  const {
    isMobile,
    showHamburger,
    showNavMenu,
    toggleNavMenu,
    username,
    logout,
  } = props;

  const hamburger = showHamburger ? (
    <Button
      icon={showNavMenu ? "menu-fold" : "menu-unfold"}
      onClick={toggleNavMenu}
    />
  ) : null;

  return (
    <Header
      style={{
        background: "#fff",
        borderBottom: "1px solid #ccc",
        boxSizing: "border-box",
        width: "100%",
        margin: 0,
        padding: "0 16px",
      }}
    >
      <Row
        gutter={16}
        style={{
          height: "100%",
          alignItems: "center",
        }}
      >
        <Col span={8}>
          {hamburger}
          <img
            src={logo}
            alt="Forwood Safety"
            style={{ maxWidth: "100%", maxHeight: 32 }}
          />
        </Col>
        <Col span={16} style={{ textAlign: "right" }} className="header-right">
          <LanguageSelector />
          <Dropdown
            overlayStyle={{ zIndex: 10000001 }}
            trigger={["click"]}
            overlay={UserMenu(logout)}
          >
            {isMobile ? (
              <Button size="large" shape="circle">
                <UserOutlined style={{ fontSize: 16 }} />
              </Button>
            ) : (
              <Button size="large">
                <UserOutlined style={{ fontSize: 18 }} />
                {decodeURIComponent(username)}
              </Button>
            )}
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

const UserMenu = (logout) => (
  <Menu onClick={logout}>
    <Menu.Item key="logout">
      <Text>Logout</Text>
    </Menu.Item>
  </Menu>
);
