import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AbilityContext } from "./Components/Can";
import ability from "./Components/Can/ability";
import { BrowserRouter as Router } from "react-router-dom";

/* IE11 Polyfills */
import "core-js";
import "react-app-polyfill/ie11";

import "./index.css";
import App from "./App";
import store from "./store";
import { Switch, Route } from "react-router";

/* <Provider /> initialises Redux. Future React concurrent changes will go hre */
ReactDOM.render(
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <App />
          </Route>
        </Switch>
      </Router>
    </AbilityContext.Provider>
  </Provider>,

  document.getElementById("root")
);
