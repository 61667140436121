import config from '../Config';

/*
 * This file is separate from middleware/api so that we can test the middleware
 * by mocking it.
 */

/*
 * nextToken parameter is used by verifications and incidents endpoints
 * to "page" the data. We use it here to make the requests act like
 * a "while" loop. We fire the callback after every "page" of data, not just
 * the last page. This allows the application to load in markers gradually.
 *
 * The getData property of the requestParams object is a function that
 * transforms the API response into a preferred format. We do it this
 * way instead of in the code that calls this function so that the nextToken
 * functionality works.
 */
export default function makeRequest(store) {
  /*
   * API_CALL_FINISHED is used by the "map" reducer to set loading to false.
   * If status has error, as in this case it is picked up by notifications
   * middleware and an error is displayed
   */
  function handleRequestError(id, description) {
    store.dispatch({
      type: 'API_CALL_FINISHED',
      status: 'error',
      id,
      description,
    });
  }

  /* Converts Javascript {key: value} object into URL query parameters. */
  function toGetParams(obj) {
    return Object.keys(obj).reduce((str, k, i) => {
      const token = i === 0 ? '?' : '&';
      return str + `${token}${k}=${obj[k]}`;
    }, '');
  }

  return (requestParams, cb, nextToken) => {
    const {
      path,
      id,
      errorMessage,
      params,
      getData,
      baseUrl = config.proxy_path,
      method = 'GET',
    } = requestParams;

    const state = store.getState();

    /* Is used in "map" reducer to set loading[id] to true */
    store.dispatch({type: 'API_CALL_STARTED', id});

    /* Never make an API call is user is unauthorised */
    // if (state.auth.unauthorised) return;

    /*
     * This is normally only triggered if a user session has timed out. Also
     * here as a fail-safe.
     */
    if (!state.auth.accessToken)
      handleRequestError(
        id,
        'Invalid user session. Please reload the page to sign in',
      );

    const urlParams = nextToken
      ? {
          ...params,
          next_token: nextToken,
        }
      : params;

    const url = `${baseUrl}${path}${toGetParams(urlParams || {})}`;

    /* Cognito token required for all REST calls */
    const headers = new Headers({
      Authorization: state.auth.idTokenJwtString,
    });

    fetch(url, {method, headers})
      .then(res => res.json())
      .then(responseData => {
        const data = getData(responseData);
        cb(data);
        if (responseData.nextToken) {
          makeRequest(store)(requestParams, cb, responseData.nextToken);
        } else {
          store.dispatch({type: 'API_CALL_FINISHED', id});
        }
      })
      .catch(err => console.error(err) || handleRequestError(id, errorMessage));
  };
}
