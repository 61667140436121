import Config from "../Config";

const APP_CONFIG_SET_ACTION = "APP_CONFIG_SET";

export function appConfigDefaultState() {
  return null;
}

export async function getAppConfig(dispatch) {
  const result = await fetch(`${Config.apiGateway.URL}/app-config`);
  const payload = await result.json();
  console.log("call redux set AppConfig");
  dispatch({
    type: APP_CONFIG_SET_ACTION,
    payload,
  });
}

export default (state = appConfigDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case APP_CONFIG_SET_ACTION: {
      console.log("set appconfig = ", payload);
      return {
        maintenanceMode: payload.maintenance_mode,
        appVersion: payload.app_version,
        enableSupportMenu: payload.support_menu_enabled,
        intercomEnabled: payload.intercom_enabled,
      };
    }

    default:
      return state;
  }
};
