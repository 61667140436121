import { connect } from "react-redux";
import get from "lodash/get";

import Component from "../Components/Header";
import { logout } from "../reducers/auth";

const mapStateToProps = (state) => {
  return {
    isMobile: state.ui.isMobile,
    showHamburger: state.ui.navMenuEnabled,
    showNavMenu: state.ui.showNavMenu,
    username: get(state.auth.idToken, "email"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavMenu() {
      dispatch({ type: "UI_TOGGLE_NAV_MENU" });
    },
    logout() {
      logout(dispatch);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
