import React from "react";
import { Dropdown, Button, Menu } from "antd";
import { GlobalOutlined } from "@ant-design/icons";

import Text from "./Text";

/*
 * The values of these should have matching key in each Translations/<lang>.js
 * file. For example in Translations/fr.js we have:
 * English (English): "Anglais (English)"
 */
const langMap = {
  en: "English (English)",
  es: "Spanish (Español)",
  fr: "French (Français)",
  mn: "Mongolian (Монгол хэл)",
  pt: "Portuguese (Português)",
  ru: "Russian (русский)",
  nb: "Norwegian (Norsk)",
  zh: "Chinese, Simplified (简化字)",
  nl: "Dutch (Nederlands)",
  de: "German (Deutsch)",
  it: "Italian (Italiano)",
  is: "Icelandic (Íslensku)",
  sr: "Serbian (Српски)",
  fi: "Finnish (Suomalainen)",
  id: "Indonesian (Bahasa Indonesia)",
  ja: "Japanese (日本語)",
  tr: "Turkish (Türkçe)",
};

export default ({ setLanguage, isMobile, locale }) => {
  const menu = (
    <Menu onClick={(o) => setLanguage(o.key)}>
      <Menu.Item key="en">
        <Text>English (English)</Text>
      </Menu.Item>
      <Menu.Item key="es">
        <Text>Spanish (Español)</Text>
      </Menu.Item>
      <Menu.Item key="fr">
        <Text>French (Français)</Text>
      </Menu.Item>
      <Menu.Item key="mn">
        <Text>Mongolian (Монгол хэл)</Text>
      </Menu.Item>
      <Menu.Item key="nb">
        <Text>Norwegian (Norsk)</Text>
      </Menu.Item>
      <Menu.Item key="nl">
        <Text>Dutch (Nederlands)</Text>
      </Menu.Item>
      <Menu.Item key="pt">
        <Text>Portuguese (Português)</Text>
      </Menu.Item>
      <Menu.Item key="ru">
        <Text>Russian (русский)</Text>
      </Menu.Item>
      <Menu.Item key="zh">
        <Text>Chinese, Simplified (简化字)</Text>
      </Menu.Item>
      <Menu.Item key="de">
        <Text>German (Deutsch)</Text>
      </Menu.Item>
      <Menu.Item key="it">
        <Text>Italian (Italiano)</Text>
      </Menu.Item>
      <Menu.Item key="is">
        <Text>Icelandic (Íslensku)</Text>
      </Menu.Item>
      <Menu.Item key="sr">
        <Text>Serbian (Српски)</Text>
      </Menu.Item>
      <Menu.Item key="fi">
        <Text>Finnish (Suomalainen)</Text>
      </Menu.Item>
      <Menu.Item key="id">
        <Text>Indonesian (Bahasa Indonesia)</Text>
      </Menu.Item>
      <Menu.Item key="ja">
        <Text>Japanese (日本語)</Text>
      </Menu.Item>
      <Menu.Item key="tr">
        <Text>Turkish (Türkçe)</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlayStyle={{ zIndex: 10000001 }}
      trigger={["click"]}
      overlay={menu}
    >
      {isMobile ? (
        <Button style={{ marginRight: 20 }} size="large" shape="circle">
          <GlobalOutlined style={{ fontSize: 16 }} />
        </Button>
      ) : (
        <Button size="large" style={{ marginRight: 20 }}>
          <GlobalOutlined style={{ fontSize: 16 }} />{" "}
          <Text>{langMap[locale]}</Text>
        </Button>
      )}
    </Dropdown>
  );
};
