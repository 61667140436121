import { Ability, AbilityBuilder } from '@casl/ability';

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  // this one is required
  // eslint-disable-next-line no-underscore-dangle
  return item.__type;
}

const ability = new Ability([], { subjectName });

export function updateAbility(currentAbility, decodedIdToken) {
  const { can, rules } = new AbilityBuilder();

  if (
    !decodedIdToken
    || !decodedIdToken['cognito:groups']
    || !decodedIdToken['cognito:groups'].length
  ) {
    return;
  }

  // const cognitoGroups = decodedIdToken['cognito:groups'];
  // const jobRoleCategory = decodedIdToken.job_role_category;

  // if (![].every((role) => cognitoGroups.includes(role))) {
  //   return;
  // }

  can('view', 'HomePage');

  currentAbility.update(rules);
}

export default ability;
